import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeixin, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import stillLifeImage from './assets/images/still-life1.jpg';

const Contact = () => {
  return (
    <div style={{backgroundColor: '#1D2828' }}>
      <div className="gallery-contact" style={{ backgroundImage: `url(${stillLifeImage})`, justifyContent:'center' }}>
        <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
          <h1
            style={{
              whiteSpace: 'pre-wrap',
              transitionTimingFunction: 'ease',
              transitionDuration: '1.5s',
              transitionDelay: '0.32s',
              color: 'white',
              fontWeight: 'bold',
              fontFamily: 'Lora', 
              fontSize: "60px"
            }}
            className="preFade fadeIn"
          >
            Contact us to schedule a service or for any inquiries.
          </h1>
        </div>
      </div>

      <div className="contact-container" style={{ marginTop: '20px', fontFamily: 'Lora', }}>
        <h1 className="slideIn">Contact Us</h1>

        <section className="contact-section slideIn">
          <h2>Address</h2>
          <p>4779 Steeles Ave E b02, Scarborough, ON M1V 4S7</p>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.5549425452113!2d-79.28273438450265!3d43.81680157911565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d2aa8dcda0d7%3A0x86f5eab0c90dfeb7!2s4779%20Steeles%20Ave%20E%2C%20Scarborough%2C%20ON%20M1V%204S7%2C%20Canada!5e0!3m2!1sen!2sus!4v1633969136110!5m2!1sen!2sus"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            className="slideIn"
          ></iframe>
        </section>

        <section className="contact-section slideIn">
          <h2>Hours</h2>
          <ul>
            <li>Saturday: 10 a.m. – 7 p.m.</li>
            <li>Sunday: 10 a.m. – 6 p.m.</li>
            <li>Monday: 10 a.m. – 7 p.m.</li>
            <li>Tuesday: 10 a.m. – 7 p.m.</li>
            <li>Wednesday: 10 a.m. – 7 p.m.</li>
            <li>Thursday: 10 a.m. – 7 p.m.</li>
            <li>Friday: 10 a.m. – 7 p.m.</li>
          </ul>
        </section>

        <section className="contact-section slideIn">
          <h2>Phone</h2>
          <p>(705) 999-2678</p>
        </section>

        <section className="contact-section slideIn">
          <h2>Follow Us</h2>
          <div className="social-icons">
            <a href="https://www.wechat.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWeixin} size="2x" className="fadeIn" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="2x" className="fadeIn" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="2x" className="fadeIn" />
            </a>
            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} size="2x" className="fadeIn" />
            </a>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Contact;
