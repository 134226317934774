import React, { useEffect, useRef } from 'react';
import stillLifeImage1 from './assets/images/unsplash2.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWeixin, faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import stillLifeImage from './assets/images/still-life1.jpg';

const Home = () => {
  const observer = useRef(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.1 }
    );

    const elements = document.querySelectorAll('.animate-on-scroll');
    elements.forEach(el => observer.current.observe(el));

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div>
      <div className="gallery animate-on-scroll" style={{fontFamily: 'Lora'}}>
        <div className="d-flex" style={{textAlign: 'center', justifyContent: 'space-evenly', alignItems: 'center', padding: '30px' }}>
            <h2
                style={{
                    whiteSpace: 'pre-wrap',
                    transitionTimingFunction: 'ease',
                    transitionDuration: '1.5s',
                    transitionDelay: '0.32s',
                    color: '#1D2828',
                    fontFamily: 'Lora',
                    fontSize: '50px'
                }}
                className="preFade fadeIn animate-on-scroll"
            >
                Welcome to M&Y Hair Salon 沐野
            </h2>
        </div>
        <img src="still-life2.jpg" alt="Still Life Photo 2" className="fadeIn animate-on-scroll" />
      </div>
      
      <div>
        <div className="Service-Wrapper animate-on-scroll" style={{ backgroundImage: `url(${stillLifeImage1})`, justifyContent:'center', paddingTop: '80px', paddingBottom: '80px',  fontFamily: 'Lora',  }}>
        <div className="services-container animate-on-scroll">
          <h1 className="slideIn animate-on-scroll">Our Services / 服务项目</h1>

          <section className="service-section slideIn animate-on-scroll">
            <h2>Cut and Style / 洗剪吹</h2>
            <ul>
              <li>Women’s Cut + Style / 女士剪发 + 造型: $30</li>
              <li>Men’s Cut + Style / 男士剪发 + 造型: $25</li>
              <li>Director (Women) / 总监设计（女士）: $45</li>
              <li>Director (Men) / 总监设计（男士）: $35</li>
            </ul>
          </section>

          <section className="service-section slideIn animate-on-scroll">
            <h2>Perm / 烫发</h2>
            <ul>
              <li>Men’s Perm / 男士烫发: $80 起 (and up)</li>
              <li>Women’s Cold Perm / 女士冷烫: $100 起 (and up)</li>
              <li>Women’s Hot Perm / 女士热烫: $200 起 (and up)</li>
              <li>Root Perm / 顶部增高烫: $100 起 (and up)</li>
              <li>Japanese Straight Perm / 顺直/离子烫: $150 起 (and up)</li>
              <li>BC Protein Straight Perm / 蛋白矫正: $180 起 (and up)</li>
            </ul>
          </section>

          <section className="service-section slideIn animate-on-scroll">
            <h2>Colour / 染发</h2>
            <ul>
              <li>Men’s Micro Hair Colour / 男士微潮色: $80 起 (and up)</li>
              <li>Partial Highlights / 区域染: $100 起 (and up)</li>
              <li>Highlights / 挑染: $350 起 (and up)</li>
              <li>Balayage Highlights / 巴黎染发: $350 起 (and up)</li>
            </ul>
          </section>

          <section className="service-section slideIn animate-on-scroll">
            <h2>Extensions / 接发</h2>
            <ul>
              <li>Feather Hair Extensions / 极致O型羽毛接发: $600 起 (and up)</li>
              <li>6-Dominance Smart Hair Extension Machine / 极致O型机器接发: $600 起 (and up)</li>
            </ul>
          </section>


          <section className="service-section slideIn animate-on-scroll">
            <h2>Treatment / 护理</h2>
            <ul>
              <li>Deep Conditioning Treatment / 深层护理: $50 起 (and up)</li>
              <li>Kabar / 卡巴尔护理: $100 起 (and up)</li>
              <li>Hair Treatment - Kerastase / 卡诗专属护理: $140 起 (and up)</li>
              <li>Scalp Treatment & SPA / 头皮护理 & SPA: $180 起 (and up)</li>
            </ul>
          </section>

        </div>
        </div>

        <div className="gallery animate-on-scroll" style={{ padding: '0px', backgroundColor: '#D4E0E0'}}>
          <div className="d-flex" style={{textAlign: 'center', justifyContent: 'space-evenly', alignItems: 'center',  fontFamily: 'Lora',  }}>
            <h1
                style={{
                    whiteSpace: 'pre-wrap',
                    transitionTimingFunction: 'ease',
                    transitionDuration: '1.5s',
                    transitionDelay: '0.32s',
                    color: '#1D2828', 
                    padding: '40px'
                }}
                className="preFade fadeIn animate-on-scroll"
            >
                Discover our premium haircare products, designed to keep your locks looking their best. From nourishing shampoos to luxurious conditioners, styling tools, and more, we've got everything you need to achieve salon-quality hair at home.
            </h1>
          </div>
          <img src="Fletcher-02.jpg" alt="Still Life Photo 2" className="fadeIn animate-on-scroll" />
        </div>
      </div>

      <div style={{backgroundColor: '#1D2828' }}>
        <div className="gallery-contact animate-on-scroll" style={{ backgroundImage: `url(${stillLifeImage})`, justifyContent:'center' }}>
          <div className="d-flex" style={{ textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
            <h1
              style={{
                whiteSpace: 'pre-wrap',
                transitionTimingFunction: 'ease',
                transitionDuration: '1.5s',
                transitionDelay: '0.32s',
                color: 'white',
                fontWeight: 'bold',
                fontFamily: 'Lora', 
                fontSize: "60px"
              }}
              className="preFade fadeIn animate-on-scroll"
            >
              Contact us to schedule a service or for any inquiries.
            </h1>
          </div>
        </div>

        <div className="contact-container animate-on-scroll" style={{ marginTop: '20px', fontFamily: 'Lora', }}>
          <h1 className="slideIn animate-on-scroll">Contact Us</h1>

          <section className="contact-section slideIn animate-on-scroll">
            <h2>Address</h2>
            <p>4779 Steeles Ave E b02, Scarborough, ON M1V 4S7</p>
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2887.5549425452113!2d-79.28273438450265!3d43.81680157911565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d2aa8dcda0d7%3A0x86f5eab0c90dfeb7!2s4779%20Steeles%20Ave%20E%2C%20Scarborough%2C%20ON%20M1V%204S7%2C%20Canada!5e0!3m2!1sen!2sus!4v1633969136110!5m2!1sen!2sus"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              className="slideIn animate-on-scroll"
            ></iframe>
          </section>

          <section className="contact-section slideIn animate-on-scroll">
            <h2>Hours</h2>
            <ul>
              <li>Saturday: 10 a.m. – 7 p.m.</li>
              <li>Sunday: 10 a.m. – 6 p.m.</li>
              <li>Monday: 10 a.m. – 7 p.m.</li>
              <li>Tuesday: 10 a.m. – 7 p.m.</li>
              <li>Wednesday: 10 a.m. – 7 p.m.</li>
              <li>Thursday: 10 a.m. – 7 p.m.</li>
              <li>Friday: 10 a.m. – 7 p.m.</li>
            </ul>
          </section>

          <section className="contact-section slideIn animate-on-scroll">
            <h2>Phone</h2>
            <p>(705) 999-2678</p>
          </section>

          <section className="contact-section slideIn animate-on-scroll">
            <h2>Follow Us</h2>
            <div className="social-icons">
              <a href="https://www.wechat.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWeixin} size="2x" className="fadeIn animate-on-scroll" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faInstagram} size="2x" className="fadeIn animate-on-scroll" />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2x" className="fadeIn animate-on-scroll" />
              </a>
              <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} size="2x" className="fadeIn animate-on-scroll" />
              </a>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Home;
