import React from 'react';
import stillLifeImage from './assets/images/unsplash2.jpg';

const Services = () => {
  return (
    <div>

      
      <div>
        <div className="Service-Wrapper" style={{ backgroundImage: `url(${stillLifeImage})`, justifyContent:'center', paddingTop: '80px', paddingBottom: '80px',  fontFamily: 'Lora',  }}>
          <div className="services-container">
            <h1 className="slideIn">Our Services / 服务项目</h1>

            <section className="service-section slideIn">
              <h2>Cut and Style / 洗剪吹</h2>
              <ul>
                <li>Women’s Cut + Style / 女士剪发 + 造型: $30</li>
                <li>Men’s Cut + Style / 男士剪发 + 造型: $25</li>
                <li>Director (Women) / 总监设计（女士）: $45</li>
                <li>Director (Men) / 总监设计（男士）: $35</li>
              </ul>
            </section>

            <section className="service-section slideIn">
              <h2>Perm / 烫发</h2>
              <ul>
                <li>Men’s Perm / 男士烫发: $80 起 (and up)</li>
                <li>Women’s Cold Perm / 女士冷烫: $100 起 (and up)</li>
                <li>Women’s Hot Perm / 女士热烫: $200 起 (and up)</li>
                <li>Root Perm / 顶部增高烫: $100 起 (and up)</li>
                <li>Japanese Straight Perm / 顺直/离子烫: $150 起 (and up)</li>
                <li>BC Protein Straight Perm / 蛋白矫正: $180 起 (and up)</li>
              </ul>
            </section>

            <section className="service-section slideIn">
              <h2>Colour / 染发</h2>
              <ul>
                <li>Men’s Micro Hair Colour / 男士微潮色: $80 起 (and up)</li>
                <li>Partial Highlights / 区域染: $100 起 (and up)</li>
                <li>Highlights / 挑染: $350 起 (and up)</li>
                <li>Balayage Highlights / 巴黎染发: $350 起 (and up)</li>
              </ul>
            </section>

            <section className="service-section slideIn">
              <h2>Extensions / 接发</h2>
              <ul>
                <li>Feather Hair Extensions / 极致O型羽毛接发: $600 起 (and up)</li>
                <li>6-Dominance Smart Hair Extension Machine / 极致O型机器接发: $600 起 (and up)</li>
              </ul>
            </section>

            <section className="service-section slideIn">
              <h2>Treatment / 护理</h2>
              <ul>
                <li>Deep Conditioning Treatment / 深层护理: $50 起 (and up)</li>
                <li>Kabar / 卡巴尔护理: $100 起 (and up)</li>
                <li>Hair Treatment - Kerastase / 卡诗专属护理: $140 起 (and up)</li>
                <li>Scalp Treatment & SPA / 头皮护理 & SPA: $180 起 (and up)</li>
              </ul>
            </section>
          </div>
        </div>

        <div className="gallery" style={{ padding: '0px', backgroundColor: '#D4E0E0'}}>
          <div className="d-flex" style={{textAlign: 'center', justifyContent: 'space-evenly', alignItems: 'center',  fontFamily: 'Lora',  }}>
            <h1
                style={{
                    whiteSpace: 'pre-wrap',
                    transitionTimingFunction: 'ease',
                    transitionDuration: '1.5s',
                    transitionDelay: '0.32s',
                    color: '#1D2828', 
                    padding: '40px'
                }}
                className="preFade fadeIn"
            >
                Discover our premium haircare products, designed to keep your locks looking their best. From nourishing shampoos to luxurious conditioners, styling tools, and more, we've got everything you need to achieve salon-quality hair at home.
            </h1>
          </div>
          <img src="Fletcher-02.jpg" alt="Still Life Photo 2" className="fadeIn" />
        </div>
      </div>
    </div>

  );
};

export default Services;
