import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Home from './Home';
import About from './About';
import Services from './Services';
import Contact from './Contact';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


const App = () => {
  const theme = {
    footerStyle: {
      backgroundColor: '#333',
      color: 'white',
    },
    navStyle: {
      color: 'white',
    },
    fontFamily: 'Arial, sans-serif',
  };

  const currentYear = new Date().getFullYear();


  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">
            <img src="/hairsalonlogo.png" alt="Logo" className="logo mr-auto" />
          </Link>
          <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/services">Services</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav>
        </header>
        <main className="flex-grow-1">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
        <footer
          className="d-flex justify-content-center align-items-center"
          style={{
            backgroundColor: theme.footerStyle.backgroundColor,
            height: '120px',
            width: '100%',
            color: theme.footerStyle.color,
            fontWeight: 'bold',
            fontFamily: 'Lora', 
            fontSize: "60px"
          }}
        >
          <h2
            className="text-center"
            style={{
              color: theme.navStyle.color,
              marginBottom: '0px',
              fontFamily: 'Lora', 
              fontSize: "14px"
            }}
          >
            Copyright © {currentYear}
            <br />
            Website provided by
            <a
              href="https://imxiaow.github.io/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: theme.footerStyle.color }}
            >
              {' '}
              XW
            </a>{' '}
            @
            <a
              href="https://www.infinityvc.ca/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: theme.footerStyle.color }}
            >
              {' '}
              Infinity Vision Consulting Inc.
            </a>
          </h2>
        </footer>
      </div>
    </Router>
  );
};

export default App;
