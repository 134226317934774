import React from 'react';

const About = () => {
  return (
    <div style={{ fontFamily: 'Lora', }}>
      <div className="gallery">
        <div className="d-flex" style={{textAlign: 'center', justifyContent: 'space-evenly', alignItems: 'center',  }}>
            <h1
                style={{
                    whiteSpace: 'pre-wrap',
                    transitionTimingFunction: 'ease',
                    transitionDuration: '1.5s',
                    transitionDelay: '0.32s',
                    color: '#1D2828',
                }}
                className="preFade fadeIn"
            >
                Learn more about M&Y Hair Salon 沐野 and our story.
            </h1>
        </div>
        <img src="unsplash1.jpg" alt="Still Life Photo 2" />
      </div>


      <div className="about-container" style={{ padding: '20px',  }}>
        <p style={{ fontWeight:'normal', fontSize: '18px' }}>
          Discover M&Y Hair Salon 沐野, your top choice for professional hair services. We specialize in expert haircuts, stylish coloring, precise bleaching, and quality extensions for men, women, and children. Our highly skilled team ensures excellent service in a comfortable and modern environment. Whether you need a trendy haircut, vibrant dye, detailed highlights, M&Y Hair Salon 沐野 has you covered. Experience the perfect blend of fashion and technique. Visit us for top-quality hair services that will leave you looking and feeling your best.
        </p>
       
      </div>

      <div style={{ marginTop: '10px', width: '100vw' }}>
        <img style={{width: '100%'}} src="fall2.jpg" alt="Still Life Photo 2" />
      </div>
      
    </div>
  );
};

export default About;
